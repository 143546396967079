<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="attendance" :class="'btn btn-success text-light'">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</router-link>
            <!-- <b-button variant="primary" @click="pdfExport" class="mr-2">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button> -->
          </template>
          <template v-slot:body>
            <b-row>
                <b-col>
                </b-col>
            </b-row>
            <b-overlay :show="loading">
              <!-- <b-row v-if="details.length > 0"> -->
              <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple class="tg mt-3" hover small caption-top>
                      <tbody>
                        <tr>
                          <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                          <th class="text-center" style="width: 3%">:</th>
                          <td style="width: 32%" class="text-left">{{ formData.circular_memo_no }}</td>
                          <th style="width: 15%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
                          <th class="text-center" style="width: 3%">:</th>
                          <td style="width: 32%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                        </tr>
                        <tr>
                          <th style="width: 15%"  class="text-left">{{ $t('elearning_config.organization') }}</th>
                          <th style="width: 3%" class="text-center">:</th>
                          <td style="width: 32%" class="text-left">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
                          <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                          <th style="width: 3%" class="text-center">:</th>
                          <td style="width: 32%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                        </tr>
                        <tr>
                          <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                          <th style="width: 3%" class="text-center">:</th>
                          <td style="width: 32%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                          <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                          <th style="width: 3%" class="text-center">:</th>
                          <td style="width: 32%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                        </tr>
                        <tr>
                          <th style="width: 15%"  class="text-left">{{ $t('elearning_iabm.batch_no') }}</th>
                          <th style="width: 3%" class="text-center">:</th>
                          <td style="width: 32%">{{ $i18n.locale === 'bn' ? formData.batch_bn : formData.batch }}</td>
                          <th style="width: 15%"  class="text-left"></th>
                          <th style="width: 3%" class="text-center"></th>
                          <td style="width: 32%"></td>
                        </tr>
                      </tbody>
                    </b-table-simple>
                  </b-col>
              </b-row>
              <b-row id="form">
                <div class="col-md-12">
                  <fieldset class="p-2 w-100">
                    <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</legend>
                      <!-- <div style="border: 2px solid #EBEBEF ;margin:10px;padding:10px">
                          <b-row>
                              <b-col v-for="(info, index) in dateList" :key="index">
                                <b-form-checkbox
                                  :id="'checkbox-' + info.text"
                                  v-model="info.checked"
                                  :name="'checkbox-' + info.text"
                                  value=2
                                  unchecked-value=1
                                  @change="checkCount(info)"
                                >
                                {{info.text | dateFormat}}
                                </b-form-checkbox>
                              </b-col>
                          </b-row>
                      </div> -->
                      <b-overlay :show="loading">
                        <b-table-simple hover bordered responsive >
                          <b-thead class="thead">
                            <b-tr>
                              <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('globalTrans.sl_no')}}</b-th>
                              <b-th rowspan="3" style="vertical-align:middle">{{$t('elearning_tim.trainee')}}</b-th>
                              <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('globalTrans.designation')}}</b-th>
                              <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('elearning_tim.posting_office')}}</b-th>
                              <b-th rowspan="3" style="vertical-align:middle">{{$t('globalTrans.mobile')}}</b-th>
                              <b-th style="width:10%;vertical-align:middle" class="text-center" :colspan="dateList.length * 2">{{ $t('globalTrans.date') }}</b-th>
                            </b-tr>
                          <b-tr>
                            <slot v-for="(field) in dateList">
                              <b-th class="text-center" colspan="2">
                                  {{ field.text | dateFormat }}
                              </b-th>
                            </slot>
                          </b-tr>
                          <b-tr>
                            <slot v-for="field in dateList">
                              <b-th class="text-center" :id="'morning-' + field.value">
                                {{$t('globalTrans.morning')}}
                              </b-th>
                              <b-th class="text-center" :id="'day-' + field.value">
                                {{$t('globalTrans.evening')}}
                              </b-th>
                            </slot>
                          </b-tr>
                          </b-thead>
                          <b-tr v-for="(train,index) in details" :key="index">
                            <b-td class="text-center">{{$n(index+1)}}</b-td>
                            <b-td>
                                {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                            </b-td>
                            <b-td class="text-center">
                              {{ ($i18n.locale==='bn') ? train.designation_name_bn : train.designation_name }}
                            </b-td>
                            <b-td class="text-center">
                              {{ (($i18n.locale==='bn') ? train.office_name_bn : train.office_name) }}
                              <!-- {{ (($i18n.locale==='bn') ? train.office_name_bn : train.office_name) + ', ' + (($i18n.locale==='bn') ? train.org_name_bn : train.org_name) }} -->
                            </b-td>
                            <b-td class="text-center">
                              {{ train.mobile | mobile }}
                            </b-td>
                            <slot v-for="(field, index1) in dateList">
                              <b-th class="text-center">
                                <span v-if="details[index].dateList[index1].morning">{{ $t('elearning_tim.present') }}</span>
                                <!-- <span v-else>{{ $t('elearning_tim.absent') }}</span> -->
                              </b-th>
                              <b-th class="text-center">
                                <span v-if="details[index].dateList[index1].day">{{ $t('elearning_tim.present') }}</span>
                                <!-- <span v-else>{{ $t('elearning_tim.absent') }}</span> -->
                              </b-th>
                            </slot>
                          </b-tr>
                          <template v-if="details.length === 0">
                            <tr>
                              <th :colspan="dateList.length * 2" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                            </tr>
                          </template>
                        </b-table-simple>
                      </b-overlay>
                  </fieldset>
                </div>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
        <!-- <pre>{{ details }}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
// import { ValidationObserver, ValidationProvider } from 'vee-validate'
// import { ValidationObserver } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendanceStore, circularPublication, trainingApplicationIndividualList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ExportPdf from './export_pdf_attendence_details'

export default {
  props: ['id'],
  components: {
    // ValidationObserver,
    // ValidationProvider,
  },
  created () {
    this.getCircularMemoNo()
    this.getAttedenceList()
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: true,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: this.$route.query.circularMemoNo,
        org: '',
        org_bn: '',
        office: '',
        office_bn: '',
        office_type: '',
        office_type_bn: '',
        fiscal_year: '',
        fiscal_year_bn: '',
        training_category: '',
        training_category_bn: '',
        training_type: '',
        training_type_bn: '',
        training_title_id: 0,
        training_title: '',
        training_title_bn: '',
        batch_id: '',
        batch: '',
        batch_bn: '',
        training_date: '',
        training_start_date: '',
        training_end_date: '',
        details: []
      },
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      details: [],
      officeTypeList: [],
      detailsErrors: [],
      trainerLoading: false,
      circularList: [],
      circularLoading: false,
      officeList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainingTitleList: [],
      circularWiseAttendanceList: [],
      detailErrors: [],
      dateList: []
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
      ]
    },
    trainerList: function () {
      const listObject = this.trainerListData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    venus: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    pdfExport () {
        const reportTitle = this.$t('elearning_tim.attendance') + ' ' + this.$t('globalTrans.details')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.formData, reportTitle, this.formData, this, this.details, this.dateList.filter(item1 => item1.checked === '2'))
    },
    checkCount (item) {
      const total = this.dateList.filter(item1 => item1.checked === '2')
      if (total.length > 4) {
        this.$toast.error({
          title: 'Error',
          message: this.$i18n.locale === 'bn' ? 'সর্বোচ্চ চারটি তারিখ যোগ করা যাবে!' : 'Maximum Four dates can be added!'
        })
        item.checked = false
        item.checAll = false
      }
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async getAttedenceList () {
      if (this.$route.query.circularMemoNo && this.$route.query.batchNo) {
        const data = {
          circular_memo_no: this.$route.query.circularMemoNo,
          batch_no: this.$route.query.batchNo
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationIndividualList, data)
        if (result.success) {
        //   var temp = []
          this.circularWiseAttendanceList = result.circularWiseAttendance
          const datas = result.data.map(item => {
            var fertilizerAgent = []
            // const desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            // const desigData = {}
            // if (typeof desigObj !== 'undefined') {
            //   desigData.designation_name = desigObj.text_en
            //   desigData.designation_name_bn = desigObj.text_bn
            // } else {
            //   desigData.designation_name = ''
            //   desigData.designation_name_bn = ''
            // }
            const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
            const batchData = {}
            if (typeof batchObj !== 'undefined') {
              batchData.batch = batchObj.text_en
              batchData.batch_bn = batchObj.text_bn
            } else {
              batchData.batch = ''
              batchData.batch_bn = ''
            }
            this.dateList.forEach((dateList, dateListIndex) => {
                const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === dateList.text)
                const items = {}
                items.training_application_id = item.id
                if (typeof saveObj !== 'undefined') {
                  items.morning = saveObj.morning
                  items.day = saveObj.day
                  items.text = dateList.text
                } else {
                  items.morning = false
                  items.day = false
                  items.text = dateList.text
                }
                fertilizerAgent.push(items)
            })
            const newData = {
                dateList: fertilizerAgent
            }
            // temp.push(fertilizerAgent)
            const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            // const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(office => office.value === parseInt(item.professional_office_id))
            // const officeData = {}
            // if (typeof officeObj !== 'undefined') {
            //   officeData.office_name = officeObj.text_en
            //   officeData.office_name_bn = officeObj.text_bn
            // } else {
            //   officeData.office_name = ''
            //   officeData.office_name_bn = ''
            // }
            // return Object.assign({}, item, batchData, desigData, newData, orgData, officeData)
            return Object.assign({}, item, batchData, newData, orgData)
          })
          this.details = datas
          this.loading = false
        } else {
          this.details = []
          this.loading = false
        }
      }
    },
    checkData (item) {
        this.details.forEach((detail, detailIndex) => {
            detail.dateList.forEach((dateList, dateListIndex) => {
                if (dateList.text === item.text) {
                    if (item.checkAll === '2') {
                        dateList.checkData = true
                    } else {
                        dateList.checkData = false
                    }
                }
            })
        })
    },
    async getCircularMemoNo () {
      this.load = true
      const params = {
        circular_memo_no: this.formData.circular_memo_no,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
          this.lcErrorMsg = ''
          const trData = result.data
          this.formData.training_title_id = trData.training_title_id
          const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(trData.org_id))
          if (typeof orgObj !== 'undefined') {
              this.formData.org = orgObj.text_en
              this.formData.org_bn = orgObj.text_bn
          } else {
              this.formData.org = ''
              this.formData.org_bn = ''
          }

          const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(doc => doc.value === parseInt(trData.office_id))
          if (typeof officeObj !== 'undefined') {
              this.formData.office = officeObj.text_en
              this.formData.office_bn = officeObj.text_bn
          } else {
              this.formData.office = ''
              this.formData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(trData.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
              this.formData.fiscal_year = fiscalYearObj.text_en
              this.formData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
              this.formData.fiscal_year = ''
              this.formData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(trData.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
              this.formData.training_title = trainingTitleObj.text_en
              this.formData.training_title_bn = trainingTitleObj.text_bn
          } else {
              this.formData.training_title = ''
              this.formData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item1 => item1.value === parseInt(trData.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
              this.formData.office_type = OfficeTypeListObj.text_en
              this.formData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
              this.formData.office_type = ''
              this.formData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.find(doc => doc.value === parseInt(trData.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
              this.formData.training_type = trainingTypeObj.text_en
              this.formData.training_type_bn = trainingTypeObj.text_bn
          } else {
              this.formData.training_type = ''
              this.formData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.find(doc => doc.value === parseInt(trData.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
              this.formData.training_category = trainingCategoryObj.text_en
              this.formData.training_category_bn = trainingCategoryObj.text_bn
          } else {
              this.formData.training_category = ''
              this.formData.training_category_bn = ''
          }
          const designationObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(doc => doc.value === parseInt(trData.designation_id))
          if (typeof designationObj !== 'undefined') {
              this.formData.designation_name = designationObj.text_en
              this.formData.designation_name_bn = designationObj.text_bn
          } else {
              this.formData.designation_name = ''
              this.formData.designation_name_bn = ''
          }
          const batchObj = this.allBatchList.find(doc => doc.value === parseInt(trData.batch_no))
          if (typeof batchObj !== 'undefined') {
            this.formData.batch = batchObj.text_en
            this.formData.batch_bn = batchObj.text_bn
          } else {
            this.formData.batch = ''
            this.formData.batch_bn = ''
          }
          const listData = result.aryRange.map(item => {
              const updateData = {}
              updateData.checked = false
              updateData.checkAll = false
              updateData.text = item
              return Object.assign({}, updateData)
          })
          this.dateList = listData
          this.load = false
      // }
      this.load = false
    },
    async createData () {
      this.loading = true
      let result = null
      this.formData.details = this.details
      if (this.formData.id) {
        // formData.append('_method', 'POST')
        // result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainingScheduleUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, attendanceStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.getAttedenceList()
        this.$router.push('/training-e-learning-service/tim/attendance')
      } else {
        this.detailErrors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
