import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
// import { dateFormat, time12FormateTraining } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, detailsData, dateList) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: data.circular_memo_no, style: 'td', alignment: 'left' },
            {},
            {},
            {}
          ]
        ]
        if (Store.state.Auth.activeRoleId === 1 || Store.state.Auth.authUser.org_id === 1 || Store.state.Auth.authUser.org_id === 12) {
          bodySeperate.push([
            { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
            {},
            {},
            {}
          ]
          )
        }
        bodySeperate.push(
          [
              { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
              { text: ':', style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' },
              { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'left' },
              { text: ':', style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.office_type_bn : data.office_type, style: 'td', alignment: 'left' }
          ]
          )
        bodySeperate.push(
          [
            { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.office_bn : data.office, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
          ]
          )
          bodySeperate.push(
            [
              { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
              { text: ':', style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' },
              { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
              { text: ':', style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' }
            ]
            )
          pdfContent.push({
              table: {
                  style: 'tableData',
                  headerRows: 0,
                  widths: ['18%', '3%', '29%', '18%', '3%', '29%'],
                  body: bodySeperate
              },
              layout: {
                hLineWidth: function (i, node) {
                    return 0
                },
                vLineWidth: function (i, node) {
                    return 0
                }
              }
          })
        pdfContent.push({ text: vm.$t('elearning_tim.attendance') + ' ' + vm.$t('globalTrans.list'), style: 'hh', alignment: 'center' })
        const firstHeader = [
            { text: vm.$t('globalTrans.sl_no'), style: 'th1', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('elearning_tim.trainee'), style: 'th1', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('elearning_tim.posting_office'), style: 'th', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('globalTrans.mobile'), style: 'th1', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('globalTrans.date'), style: 'th1', colSpan: dateList.length * 2, alignment: 'center' }
        ]
        if (dateList.length > 1) {
          for (let i = 0; i < dateList.length; i++) {
            if (i === 0) {
              firstHeader.push(
                {}
              )
            } else {
              firstHeader.push(
                {},
                {}
              )
            }
          }
        }
        const allRows = [
          firstHeader
        ]
        const secondHeader = [
          {},
          {},
          {},
          {},
          {}
        ]
        const widthList = ['5%', '12%', '12%', '18%', '10%']
        dateList.forEach(dateItem => {
          secondHeader.push(
            { text: (dateItem) ? dateFormat(dateItem.text) : '', style: 'th1', alignment: 'center', bold: true, colSpan: 2 },
            {}
          )
          widthList.push('*', '*')
        })
        allRows.push(secondHeader)
        const thirdHeader = [
          {},
          {},
          {},
          {},
          {}
        ]
        dateList.forEach(dateItem => {
          thirdHeader.push(
            { text: vm.$t('globalTrans.morning'), style: 'th1', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.evening'), style: 'th1', alignment: 'center', bold: true }
          )
        })
        allRows.push(thirdHeader)
        // table body
        detailsData.filter((data, optionIndex) => {
          const rowItem = [
            { text: vm.$n(optionIndex + 1), style: 'td1', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.name_bn : data.name, style: 'td1', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? data.designation_name_bn : data.designation_name), style: 'td', alignment: 'center' },
            { text: ((vm.$i18n.locale === 'bn' ? data.office_name_bn : data.office_name)) + ', ' + ((vm.$i18n.locale === 'bn' ? data.org_name_bn : data.org_name)), style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.mobile : data.mobile, style: 'td1', alignment: 'center' }
          ]
          dateList.forEach((field, index1) => {
            let morningPresent = vm.$t('elearning_tim.present')
            if (!detailsData[optionIndex].dateList[index1].morning) {
              morningPresent = vm.$t('elearning_tim.absent')
            }
            let dayPresent = vm.$t('elearning_tim.present')
            if (!detailsData[optionIndex].dateList[index1].day) {
              dayPresent = vm.$t('elearning_tim.absent')
            }
            rowItem.push(
              { text: morningPresent, alignment: 'center', style: 'td1' },
              { text: dayPresent, alignment: 'center', style: 'td1' }
            )
          })
          allRows.push(rowItem)
        })
        pdfContent.push({
          table: {
            headerRows: 3,
            widths: widthList,
            body: allRows
          }
        })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          th1: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          td1: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
